module.exports = {
    trainer:{
        name:"Antrenör Listesi",
        endpoint:"getAllTrainers",
        title:"Türkiye Kano Federasyonu Başkanlığı - Antrenör Listesi",
        fields:["Sıra","Bölge","Ad Soyad","Vize Yılı","Kademesi/Terfi Tarihi","Kulübü","İkinci Kulübü"]
    },
    referee:{
        name:"Hakem Listesi",
        endpoint:"getAllReferees",
        title:"Türkiye Kano Federasyonu Başkanlığı - Hakem Listesi",
        fields:["Sıra","Bölge","Ad Soyad","Vize Yılı","Kademesi/Terfi Tarihi","Kulübü"]
    },
    sport:{
        name:"Sporcu Listesi",
        endpoint:"getAllSports",
        title:"Türkiye Kano Federasyonu Başkanlığı - Sporcu Listesi",
        fields:["Sıra","Bölge","Ad Soyad","Vize Yılı","Kulübü"]
    },
    club:{
        name:"Kulüp Listesi",
        endpoint:"getAllClubs",
        title:"Türkiye Kano Federasyonu Başkanlığı - Kulüp Listesi",
        fields:["Sıra","Bölge","Ad","Durgunsu Akredite Yılı","Akarsu Akredite Yılı","Deniz Kanosu Akredite Yılı","DragonBoat Akredite Yılı","Rafting Akredite Yılı"]
    },
    competitionParticipant:{
        name:"Katılımcı Listesi",
        endpoint:"getAllParticipant",
        title:"Türkiye Kano Federasyonu Başkanlığı",
        fields:["Sıra","Ad Soyad","Cinsiyet","Yaş Kategorisi","Bot Tipi","Mesafe","Kulüp Adı"]
    },
    competitionParticipantClub:{
        name:"Katılımcı Listesi",
        endpoint:"getAllParticipantClub",
        title:"Türkiye Kano Federasyonu Başkanlığı",
        fields:["Sıra","Ad Soyad","Cinsiyet","Yaş Kategorisi","Bot Tipi","Mesafe","Kulüp Adı","Katılım Şekli"]
    },
}
    
