module.exports = [
    { value: "", text: "Seçin" },
    { value: "01", text: "ADANA" },
    { value: "02", text: "ADIYAMAN" },
    { value: "03", text: "AFYONKARAHİSAR" },
    { value: "04", text: "AĞRI" },
    { value: "05", text: "AMASYA" },
    { value: "06", text: "ANKARA" },
    { value: "07", text: "ANTALYA" },
    { value: "08", text: "ARTVİN" },
    { value: "09", text: "AYDIN" },
    { value: "10", text: "BALIKESİR" },
    { value: "11", text: "BİLECİK" },
    { value: "12", text: "BİNGÖL" },
    { value: "13", text: "BİTLİS" },
    { value: "14", text: "BOLU" },
    { value: "15", text: "BURDUR" },
    { value: "16", text: "BURSA" },
    { value: "17", text: "ÇANAKKALE" },
    { value: "18", text: "ÇANKIRI" },
    { value: "19", text: "ÇORUM" },
    { value: "20", text: "DENİZLİ" },
    { value: "21", text: "DİYARBAKIR" },
    { value: "22", text: "EDİRNE" },
    { value: "23", text: "ELAZIĞ" },
    { value: "24", text: "ERZİNCAN" },
    { value: "25", text: "ERZURUM" },
    { value: "26", text: "ESKİŞEHİR" },
    { value: "27", text: "GAZİANTEP" },
    { value: "28", text: "GİRESUN" },
    { value: "29", text: "GÜMÜŞHANE" },
    { value: "30", text: "HAKKARİ" },
    { value: "31", text: "HATAY" },
    { value: "32", text: "ISPARTA" },
    { value: "33", text: "MERSİN" },
    { value: "34", text: "İSTANBUL" },
    { value: "35", text: "İZMİR" },
    { value: "36", text: "KARS" },
    { value: "37", text: "KASTAMONU" },
    { value: "38", text: "KAYSERİ" },
    { value: "39", text: "KIRKLARELİ" },
    { value: "40", text: "KIRŞEHİR" },
    { value: "41", text: "KOCAELİ" },
    { value: "42", text: "KONYA" },
    { value: "43", text: "KÜTAHYA" },
    { value: "44", text: "MALATYA" },
    { value: "45", text: "MANİSA" },
    { value: "46", text: "KAHRAMANMARAŞ" },
    { value: "47", text: "MARDİN" },
    { value: "48", text: "MUĞLA" },
    { value: "49", text: "MUŞ" },
    { value: "50", text: "NEVŞEHİR" },
    { value: "51", text: "NİĞDE" },
    { value: "52", text: "ORDU" },
    { value: "53", text: "RİZE" },
    { value: "54", text: "SAKARYA" },
    { value: "55", text: "SAMSUN" },
    { value: "56", text: "SİİRT" },
    { value: "57", text: "SİNOP" },
    { value: "58", text: "SİVAS" },
    { value: "59", text: "TEKİRDAĞ" },
    { value: "60", text: "TOKAT" },
    { value: "61", text: "TRABZON" },
    { value: "62", text: "TUNCELİ" },
    { value: "63", text: "ŞANLIURFA" },
    { value: "64", text: "UŞAK" },
    { value: "65", text: "VAN" },
    { value: "66", text: "YOZGAT" },
    { value: "67", text: "ZONGULDAK" },
    { value: "68", text: "AKSARAY" },
    { value: "69", text: "BAYBURT" },
    { value: "70", text: "KARAMAN" },
    { value: "71", text: "KIRIKKALE" },
    { value: "72", text: "BATMAN" },
    { value: "73", text: "ŞIRNAK" },
    { value: "74", text: "BARTIN" },
    { value: "75", text: "ARDAHAN" },
    { value: "76", text: "IĞDIR" },
    { value: "77", text: "YALOVA" },
    { value: "78", text: "KARABÜK" },
    { value: "79", text: "KİLİS" },
    { value: "80", text: "OSMANİYE" },
    { value: "81", text: "DÜZCE" }
]