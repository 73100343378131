<template>
  <b-container class="mt-4">
    <b-card header-tag="header" header-class="header">
      <template #header>
        <label v-text="'Hakem Raporları'" class="mr-2 text-white"></label>
        <b-button variant="primary" class="ml-2" to="/reports">Geri</b-button>
      </template>
      <InputTile label="Bölge" :custom="true">
        <b-form-select
          v-model="form.region"
          :options="formOptions.cities"
          required
        >
        </b-form-select>
      </InputTile>
      <InputTile label="Cinsiyet" :custom="true">
        <b-form-select
          v-model="form.gender"
          :options="formOptions.gender"
          required
        >
        </b-form-select>
      </InputTile>
      <InputTile label="Kademe" :custom="true">
        <b-form-select
          v-model="form.level"
          :options="formOptions.level"
          required
        >
        </b-form-select>
      </InputTile>
      <InputTile label="Branş" :custom="true">
        <b-form-select
          v-model="form.branch"
          :options="formOptions.branch"
          required
        >
        </b-form-select>
      </InputTile>
      <InputTile label="Lisans" :custom="true">
        <b-form-select
          v-model="form.licence"
          :options="formOptions.licence"
          required
        >
        </b-form-select>
      </InputTile>
      <download-excel
      class="btn btn-success float-right mt-4 ml-2"
        :fetch="getData"
        :fields="
          reports.referee.fields.reduce((obj, e) => {
            obj[e] = e;
            return obj;
          }, {})
        "
        :worksheet="reports.referee.title"
        name="filename.xls"
      >
        Excel al
      </download-excel>
      <b-btn class="float-right mt-4" variant="primary" @click="downloadPdf"
        >Rapor Al</b-btn
      >
    </b-card>
  </b-container>
</template>

<script>
import reportHelper from "@/store/helpers/PMSReportHelper";
import reports from "@/store/constants/reports.js";
import InputTile from "../../components/forms/common/InputTile.vue";
import cities from "../../store/constants/cities.js";
import JsonExcel from "vue-json-excel";
export default {
  components: {
    InputTile,
    "download-excel":JsonExcel
  },
  data() {
    return {
      reports,
      formOptions: {
        cities: cities.map(function(e) { return e.value == "" ? {text:"Tümü",value:""}: e }),
        gender: [
          { value: "", text: "Tümü" },
          { value: "male", text: "Erkek" },
          { value: "female", text: "Kadın" },
        ],
        level: [
          { value: "", text: "Tümü" },
          { value: "candidate", text: "Aday Hakem" },
          { value: "district", text: "Bölge Hakemi" },
          { value: "national", text: "Milli Hakem" },
          { value: "international", text: "Uluslararası Hakem" },
        ],
        branch: [
          { value: "", text: "Tümü" },
          { value: "runningWater", text: "Akarsu" },
          { value: "stillWater", text: "Durgunsu" },
          { value: "seaKayaking", text: "Deniz Kanosu" },
          { value: "dragonBoat", text: "Dragon Bot" },
          { value: "rafting", text: "Rafting" },
        ],
        licence: [
          { value: "", text: "Tümü" },
          { value: "licensePending", text: "Lisansı Yenileyenler" },
          { value: "licenseEnded", text: "Lisansı Dolanlar" },
        ],
      },
      form: {
        region: "",
        gender: "",
        level: "",
        branch: "",
        licence: "",
      },
    };
  },
  methods: {
    async downloadPdf() {
      reportHelper.generateHTML(
        reports.referee.title,
        reports.referee.fields,
        await reportHelper.getPdfItemsFromServer(
          reports.referee.endpoint,
          this.form
        )
      );
    },
    async getData() {
      const data = await reportHelper.getPdfItemsFromServer(
        reports.referee.endpoint,
        this.form
      );
      return data.map((e) => {
        const row = {};
        reports.referee.fields.forEach((element, index) => {
          row[element] = e[index];
        });
        return row;
      });
    },
  },
};
</script>

<style>
.header {
  background: rgb(108, 117, 125);
  background: linear-gradient(
    180deg,
    rgb(108, 117, 125) 10%,
    rgba(53, 57, 61, 1) 85%
  );
}
</style>