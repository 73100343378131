<template>
  <b-row class="spacing">
    <b-col sm="4" class="labelContainer">
      <label v-text="label"></label>
    </b-col>
    <b-col sm="8" v-if="!custom">
      <b-form-input
        v-bind:value="value"
        v-on:input="$emit('input', $event)"
        :type="type"
        v-if="required"
        required
      />
      <b-form-input
        v-bind:value="value"
        v-on:input="$emit('input', $event)"
        :type="type"
        v-else
      />
    </b-col>
    <b-col sm="8" v-else>
      <slot />
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "InputTile",
  props: {
    label: String,
    type: String,
    value: [String, Number, File],
    required: Boolean,
    custom: Boolean,
  },
};
</script>

<style scoped>
.labelContainer {
  display: grid;
  place-items: left;
}
.spacing {
  margin-top: 0.5rem;
}
</style>